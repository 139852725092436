
















import { defineComponent } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';
import Store from '@/store';
import router from '@/router';
import { IWorkorderTemplateData } from '@/types';
import InboundMappingsTable from '@/components/MappingsTableComponent.vue';
import MappingWizardDialog from '@/components/MappingWizardComponents/MappingWizardDialog.vue';

export default defineComponent({
  components: {
    InboundMappingsTable,
    MappingWizardDialog,
  },
  setup() {
    const mappingStepTitle = 'Let\'s map the Inbound Labels that will be received.';
    const mappingStepHeaders: DataTableHeader[] = [
      {
        text: 'Work Order Template Field',
        align: 'start',
        value: 'workOrderFieldName',
      },
      { text: 'Inbound Label', value: 'inboundLabel' },
      { text: 'Automatically Update', value: 'inboundOverwrite' },
      { text: 'Actions', value: 'actions', sortable: false },
    ];

    const getSavedMappings = () => {
      Store.dispatch('inboundMapping/setMappedTemplates');
      Store.dispatch('inboundMapping/setMappings', []);
    };

    getSavedMappings();

    const openEditPage = async (value: IWorkorderTemplateData) => {
      // TODO update stores to set Template (when BE has data returned)
      await Store.dispatch('outboundMapping/resetStoreState');
      await Store.dispatch('inboundMapping/setWorkOrderTemplate', value.id);

      router.push({
        name: 'InboundMappingsCustomisation',
        params: {
          templateId: value.id,
          isPublished: value.published.toString(),
        },
      });
    };

    return {
      openEditPage,
      mappingStepTitle,
      mappingStepHeaders,
    };
  },
});
